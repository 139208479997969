//import $ from 'jquery';
//import 'jquery-match-height';

// **************************************************
// Console debug
/*if (typeof window.console === 'undefined' || app_config.ENV != 'dev') {
    window.console = {
        log: function () {
        },
        warn: function () {
        },
        error: function () {
        },
        trace: function () {
        }
    }
}*/

var is_mobile = false;
if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
    is_mobile = true;
}

// **************************************************
// Set language

if (typeof app_config.LANGUAGE === 'undefined') app_config.LANGUAGE = 'fr';

if (typeof(moment) === 'function') {
    //console.log('moment is function');
    moment.locale(app_config.LANGUAGE);
}
else{
    //console.log('moment does not exist');
}

// **************************************************
// Pjax


$('[data-pjax-container]').on('pjax:start', function(event) {
    //console.log('pjax:start');
    $(this).addClass('loading');
});

$('[data-pjax-container]').on('pjax:complete', function(event) {
    //console.log('pjax:complete');
    $(this).removeClass('loading');

});
$('[data-pjax-container]').on('pjax:timeout', function(event) {
    //console.log('pjax:timeout');
    //$(this).addClass('loading');
    event.preventDefault()
});
$('[data-pjax-container]').on('pjax:end', function(event) {
    //console.log('pjax:end');
    $(this).removeClass('loading');
});

$('[data-pjax-container]').on('pjax:popstate', function (event) {
    //console.log('pjax:popstate');
});

$(document).ready(function($){
    initializePjax();
});

initializePjax = function(event){
    if($('#pjax-pricesearch').length > 0){
        pjaxPriceSearch(event);
    }
    if($('#pjax-calendar').length > 0){
        pjaxCalendar();
    }
    if($('#pjax-reservation').length > 0){
        pjaxReservation(event);
        CheckCoupon();
    }
};

$(document).on('ready pjax:end', function(event) {
    initializePjax(event);
});


affixPriceSearch = function() {

    if(!is_mobile){
        var offset = 0;
        var affixelement = $('.form-search');

        if( affixelement.length>0){
            offset = affixelement.offset().top;
        }

        //console.log('affix offset:'+offset);

        $(window).off('.affix');
        affixelement.removeData('bs.affix').removeClass('affix affix-top affix-bottom');
        affixelement.affix({
            offset: {
                top:offset,
                bottom:0,
            }
        });
    }

};



pjaxPriceSearch = function(event){
    //console.log('pjaxPriceSearch ('+event.type+')');

    affixPriceSearch();

    // Hauteur des packages
    matchHeights('#pjax-pricesearch', '.package-price__item');

    // Liens sur le bloc de chaque package
    $('.package-price__item').on('click', function(e){
        var packagelink = $(this).find('a:first');
        //console.log(packagelink.attr('href'));
        if(packagelink){
            window.location = packagelink.attr('href');
        }
    });

    // Bootstrap select : default
    $('#pricesearch-packagetype_id').selectpicker({mobile:is_mobile});
    $('#pricesearch-duration').selectpicker({mobile:is_mobile});
    $('#pricesearch-budget').selectpicker({mobile:is_mobile});

    // Owl carousel place
    $('.owl-carousel-place').owlCarousel({
        loop:true,
        slideBy: 1,
        items: 1,
        margin:0,
        nav:true,
        dots:true,
        responsiveClass:true,
        navText: [
            '<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span><span class="sr-only">Précédent</span></a>',
            '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span><span class="sr-only">Suivant</span></a>'
        ]
    }).find('.owl-nav').wrap( '<div class="owl-controls-wrapper"></div>' ).wrap( '<div class="owl-controls"></div>' ).find('.owl-next').before('<div class="owl-middle"></div>');


    if(typeof event !== 'undefined'  && event.type !== 'ready'){
        $('html, body').animate({ scrollTop: $('#pjax-pricesearch').offset().top+(is_mobile?-50:2)}, 300, 'swing');
    }



};





var scrollspyInitialized = 0;
var formopened = false;
var oldstep = 1;
var pjaxscrollto = null;

affixNavpackage = function() {

    var offsetaffixnavpackage = 0;
    var offsetaffixsummary = 0;
    if( $('#navbar-package').length>0){
        offsetaffixnavpackage = $('#navbar-package').offset().top - 50;
    }
    if( $('#summary-affix').length>0){
        offsetaffixsummary = $('#summary-affix').offset().top;
    }

    $('#navbar-package').off('.affix'); // destroy affix
    $('#navbar-package').affix({
        offset: {
            top:offsetaffixnavpackage,
            bottom:0,
        }
    });

    $('#summary-affix').off('.affix'); // destroy affix
    $('#summary-affix').affix({
        offset: {
            top:offsetaffixsummary,
            bottom:0
        }
    });

    // Scrollspy
    if(scrollspyInitialized == 0){

        //console.log('scrollspy init');

        $('body').scrollspy({
            target: '#navbar-package',
            offset: 200
        });

        $('body').on('activate.bs.scrollspy', function () {
            //console.log('activate.bs.scrollspy');
            var currentSection = $("#navbar-package li.active > a").text();
            $('#navbar-package .affix-position').html(currentSection);
            //$('.section-summary .btn-submit').addClass('affix');
        });
        scrollspyInitialized = 1;
    }
    else{
        //console.log('scrollspy refresh');
        $('body').scrollspy('refresh');
    }

    $('#navbar-package').on('affixed-bottom.bs.affix affixed-top.bs.affix', function(e){
        //$('.section-summary .btn-submit').removeClass('affix');
    });
};


pjaxCalendar = function() {


    console.log('pjaxCalendar');

    // Bootstrap select : default
    $('.bootstrapselect').selectpicker({mobile:is_mobile});

    // Smooth link
    $('.smoothlink').on('click',function(e){
        e.preventDefault();
        $('html, body').animate({ scrollTop: $($(this).attr('href')).offset().top}, 400, 'swing');
    });

    // Smooth link to summary
    $('.detailslink a').on('click', function(e){
        $('html, body').animate({ scrollTop: $('#reservation-summary').offset().top + (is_mobile ? -50 : 0)}, 300, 'swing');
    });

    // Bouton ouvrir galerie chambres
    $('.btn-openroomgallery').on('click',function(e){
        $('html, body').animate({ scrollTop: $('#section-calendar').offset().top }, 300, 'swing');
        $('.form-calendar').addClass('form-open');
        $('html').addClass('noscroll');
        formopened = true;
        openRoomGallery();
    });

    // Bootstrap select : galerie chambres
    $('#calendarsearch-room_id_searched').on('change', function (e) {
        if($(this).val() == 'roomgallery'){
            openRoomGallery();
        }
        else{
            $(this).attr('data-previousvalue', $(this).val());
        }
    });

    // Bootstrap select : personcombo
    $('#calendarsearch-personcombo').on('change', function (e) {
        //console.log('val: ' + $(this).val());
        $('#form-calendar-row-optional-fields').addClass('hide');
        if($(this).val() == 1){ // 2 curists
            $('#calendarsearch-adults_nb').val(2).selectpicker('refresh');
            $('#calendarsearch-curists_nb').val(2).selectpicker('refresh');
            $('#calendarsearch-children_nb').val(0).selectpicker('refresh');
            $('#calendarsearch-babies_nb').val(0).selectpicker('refresh');
        }
        if($(this).val() == 2){ // 1 curist
            $('#calendarsearch-adults_nb').val(1).selectpicker('refresh');
            $('#calendarsearch-curists_nb').val(1).selectpicker('refresh');
            $('#calendarsearch-children_nb').val(0).selectpicker('refresh');
            $('#calendarsearch-babies_nb').val(0).selectpicker('refresh');
        }
        if($(this).val() == 3){ // 1 curist + 1 companion
            $('#calendarsearch-adults_nb').val(2).selectpicker('refresh');
            $('#calendarsearch-curists_nb').val(1).selectpicker('refresh');
            $('#calendarsearch-children_nb').val(0).selectpicker('refresh');
            $('#calendarsearch-babies_nb').val(0).selectpicker('refresh');
        }
        if($(this).val() == 4){ // other
            //$('#calendarsearch-adults_nb').val('').selectpicker('refresh');
            //$('#calendarsearch-curists_nb').val('').selectpicker('refresh');
            $('#form-calendar-row-optional-fields').removeClass('hide');
        }
    });

    // Bootstrap select : participants
    $('#calendarsearch-adults_nb, #calendarsearch-curists_nb, #calendarsearch-children_nb, #calendarsearch-babies_nb').on('change', function (e) {
        if ($('#calendarsearch-adults_nb').val() == 2 && $('#calendarsearch-curists_nb').val() == 2 && $('#calendarsearch-children_nb').val() == 0 && $('#calendarsearch-babies_nb').val() == 0) {
            $('#calendarsearch-personcombo').val(1).selectpicker('refresh');
        }
        else if ($('#calendarsearch-adults_nb').val() == 1 && $('#calendarsearch-curists_nb').val() == 1 && $('#calendarsearch-children_nb').val() == 0 && $('#calendarsearch-babies_nb').val() == 0) {
            $('#calendarsearch-personcombo').val(2).selectpicker('refresh');
        }
        else if ($('#calendarsearch-adults_nb').val() == 2 && $('#calendarsearch-curists_nb').val() == 1 && $('#calendarsearch-children_nb').val() == 0 && $('#calendarsearch-babies_nb').val() == 0) {
            $('#calendarsearch-personcombo').val(3).selectpicker('refresh');
        }
        else{
            $('#calendarsearch-personcombo').val(4).selectpicker('refresh');
        }

    });

    // Bootstrap select : adults/curist pour sans hébergement
    if(calendar_config.PLACE_IS_ACCOMMODATION == false){
        $('#calendarsearch-adults_nb, #calendarsearch-curists_nb').on('change', function (e) {
            $('#calendarsearch-curists_nb').val($(this).val()).selectpicker('refresh');
            $('#calendarsearch-adults_nb').val($(this).val()).selectpicker('refresh');
        });
    }


    if($('#calendarsearch-personcombo').val() == 4){
        $('#form-calendar-row-optional-fields').removeClass('hide');
    }
    $('.form-calendar').on('beforeSubmit', function(e) {
        $('.form-calendar').removeClass('form-open');
        $('html').removeClass('noscroll');
    });
    $('.form-calendar').on('submit', function(e) {
        if ($(this).find('.has-error').length) {
            return false;
        }
        if ($(this).find('.error-summary:visible').length > 0) {
            return false;
        }
    });


    // Modal open
    $('.btn-open-form-calendar').on('click', function(e){
        $('.form-calendar').addClass('form-open');
        $('html').addClass('noscroll');
        formopened = true;
    });

    // Modal close
    $('.btn-close-form-calendar').on('click', function(e){
        $('.form-calendar').removeClass('form-open');
        $('html').removeClass('noscroll');
        formopened = false;
    });

    $('.form-calendar #calendarsearch-halfdaypart').on('change', function(){
        window.location = $(this).val();
    });

    // Valider le formulaire à chaque modification
    if (Modernizr.mq('(min-width: 992px)')) {
        $('.form-calendar input, .form-calendar select').on('change', function(){

            if(
                false
                || ($(this).attr('id') == 'calendarsearch-personcombo' && $(this).val() == '4')
                || ($(this).attr('id') == 'calendarsearch-room_id_searched' && $(this).val() == 'roomgallery')
                || ($(this).attr('id') == 'calendarsearch-halfdaypart' )
            ){
            }
            else{
                $('.form-calendar').submit();
            }
        });
    }

    var hourlimit = 17;
    var hourlimit_passed = moment() > moment(hourlimit, 'hh');

    var clndr_today = moment(calendar_config.TODAY, "YYYY-MM-DD");
    var clndr_startdate = moment(calendar_config.DATE, "YYYY-MM-DD");
    var clndr_enddate = moment(calendar_config.DATE, "YYYY-MM-DD").add(1, 'year').endOf('year');

    //console.log(clndr_today);
    //console.log(clndr_startdate);

    if(clndr_today.format('YYYY-MM-DD') == clndr_startdate.format('YYYY-MM-DD') && hourlimit_passed){
        clndr_today.add(1, 'day');
        //console.log('heure passée');
    }

    var clndr_currentyear = clndr_startdate.format('YYYY');
    var clndr_currentmonth = clndr_startdate.format('M');

    //console.log('clndr_currentyear: '+clndr_currentyear);
    //console.log('clndr_currentmonth: '+clndr_currentmonth);

    var clndr_nightsnb = calendar_config.NIGHTS_NB;
    var clndr_adultsnb = calendar_config.ADULTS_NB;
    var clndr_curistsnb = calendar_config.CURISTS_NB;
    var clndr_companionsnb = calendar_config.COMPANIONS_NB;
    var clndr_childrennb = calendar_config.CHILDREN_NB;
    var clndr_babiesnb = calendar_config.BABIES_NB;
    var clndr_packageid = calendar_config.PACKAGE_ID;
    var clndr_tripid = calendar_config.TRIP_ID;
    var clndr_placeid = calendar_config.PLACE_ID;
    var clndr_placeisaccommodation = calendar_config.PLACE_IS_ACCOMMODATION;
    var clndr_roomsnb = calendar_config.ROOMS_NB;
    var clndr_roomidsearched = calendar_config.ROOM_ID_SEARCHED;
    var clndr_jsonurl = calendar_config.JSONURL;

    var clndr_dayafter = 7;

    var clndr_alreadyseen = []; // months already seen, no need to ajax load again
    var clndr_lastdateclicked = null; // last date clicked, to remember it after changing month

    var clndr_summary = $('.reservation-summary');

    // Bouger d'un mois pour forcer le calendrierà lancer onMonthChange au démarrage
    //clndr_startdate.add(1,'month');

    setEvents = function(month){

        //console.log(month);
        //console.log('you just went to ' + month.format('MMMM, YYYY'));
        //console.log('onMonthChange');
        /*if(calendarOldTarget){
         target = calendarOldTarget;
         removeSelectedDays();
         //addSelectedDays(target);
         }*/

        clndr_currentyear = month.format('YYYY');
        clndr_currentmonth = month.format('MM');

        //console.log('clndr_currentyear: '+clndr_currentyear);
        //console.log('clndr_currentmonth: '+clndr_currentmonth);

        $('.calendar-loading').show();
        $('.clndr-control-button span').hide();

        if($.inArray( clndr_currentyear+'-'+clndr_currentmonth, clndr_alreadyseen) == -1){

            var csrfToken = $('meta[name="csrf-token"]').attr("content");

            $.ajax({
                url: clndr_jsonurl,
                method: 'get',
                dataType: 'json',
                cache: false,
                data: {
                    _csrf : csrfToken,
                    package_id: clndr_packageid,
                    trip_id: clndr_tripid,
                    place_id: clndr_placeid,
                    place_is_accommodation: clndr_placeisaccommodation,
                    adults_nb: clndr_adultsnb,
                    curists_nb: clndr_curistsnb,
                    companions_nb: clndr_companionsnb,
                    children_nb: clndr_childrennb,
                    babies_nb: clndr_babiesnb,
                    rooms_nb: clndr_roomsnb,
                    room_id_searched: clndr_roomidsearched,
                    nights_nb: clndr_nightsnb,
                    view: false,
                    date: clndr_currentyear+'-'+clndr_currentmonth+'-01'
                },
                success: function(data, textStatus) {

                    //console.log('textStatus: '+textStatus);
                    //console.log('data:');
                    //console.log(data);

                    //console.warn('clndr_alreadyseen');
                    //console.warn(clndr_alreadyseen);
                    if(data.status == 1){
                        events = data.data.quotes;
                        //console.log('quotes:');
                        //console.log(data.data.quotes);
                        events_toload = [];
                        events = $.map(events, function(value, index) {return [value];});
                        //console.log(events.length);

                        if(events.length<15){
                            $('#toofewdatesotherpackages').show();
                        }

                        $.each(events, function(index, value) {
                            //console.log(value.date_begin);
                            //console.log(value.date_begin.substr(0,7));

                            if($.inArray( value.date_begin.substr(0,7), clndr_alreadyseen) != -1){
                                //console.log('deja chargé');
                                //events.remove(index);
                            }
                            else{
                                //console.log('index: '+index);
                                value.date = value.date_begin;

                                event_begin = moment(value.date_begin, 'YYYY-MM-DD');
                                event_end = moment(value.date_end, 'YYYY-MM-DD');

                                // Promo Février 2017-02-19
                                /*if(clndr_tripid == 1 && clndr_nightsnb >= 6){
                                    promofebruary_begin = moment('2017-02-19', 'YYYY-MM-DD');
                                    promofebruary_end = moment('2017-03-11', 'YYYY-MM-DD');
                                    if(promofebruary_begin <= event_begin && promofebruary_end >= event_end && clndr_placeid == 1 && clndr_placeisaccommodation == 1 ){
                                        value._special_promofebruary = '1';
                                    }
                                }*/

                                // Christmas
                                christmas_begin = moment('12-25', 'MM-DD');
                                christmas_end = moment('12-25', 'MM-DD');
                                if(event_begin <= christmas_end &&  event_end >= christmas_begin){
                                    value._special_christmas = '1';
                                }

                                // New Years Eve
                                newyearseve_begin = moment('01-01', 'MM-DD').add(1, 'year');
                                newyearseve_end = moment('01-01', 'MM-DD').add(1, 'year');
                                newyearseve_begin_next = moment('01-01', 'MM-DD').add(2, 'year');
                                newyearseve_end_next = moment('01-01', 'MM-DD').add(2, 'year');

                                if(
                                    (event_begin <= newyearseve_end &&  event_end >= newyearseve_begin)
                                    || (event_begin <= newyearseve_end_next &&  event_end >= newyearseve_begin_next)
                                ){
                                    value._special_newyearseve = '1';
                                }


                                // Vidange Aquatonic 2022-06-26 au 2022-06-27
                                vidangeaquatonic_begin = moment('2022-06-26', 'YYYY-MM-DD');
                                vidangeaquatonic_end = moment('2022-06-27', 'YYYY-MM-DD');
                                if(event_begin <= vidangeaquatonic_end &&  event_end >= vidangeaquatonic_begin){
                                    value._special_vidangeaquatonic = '1';
                                }

                                // Vidange Piscine 2017-07-25 au 2017-07-26
                                vidangepiscine_begin = moment('2017-06-25', 'YYYY-MM-DD');
                                vidangepiscine_end = moment('2017-06-25', 'YYYY-MM-DD');
                                if(event_begin <= vidangepiscine_end &&  event_end >= vidangepiscine_begin){
                                    value._special_vidangepiscine = '1';
                                }

                                // Fermeture piscine NMD 2020-01-11 au 2020-02-07
                                fermeturepiscinenmd_begin = moment('2020-01-11', 'YYYY-MM-DD');
                                fermeturepiscinenmd_end = moment('2020-02-07', 'YYYY-MM-DD');
                                if(clndr_placeid==2 && event_begin <= fermeturepiscinenmd_end &&  event_end >= fermeturepiscinenmd_begin){
                                    value._special_fermeturepiscinenmd = '1';
                                }

                                // fix price to display :
                                value.price_perperson_discounted = Number(value.price_perperson_discounted).toLocaleString(app_config.LANGUAGE,
                                    {style: "currency", currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 2});

                                value.price_perperson_regular = Number(value.price_perperson_regular).toLocaleString(app_config.LANGUAGE,
                                    {style: "currency", currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 2});

                                events_toload.push(events[index]);
                            }
                        });
                        //console.log(events_toload);
                        clndr_calendar.addEvents(events_toload);

                        clndr_alreadyseen.push(clndr_currentyear+'-'+clndr_currentmonth);



                    }
                    else{
                        clndr_alreadyseen.push(clndr_currentyear+'-'+clndr_currentmonth);
                        if(clndr_currentmonth < 12){
                            clndr_calendar.setMonth(parseInt(clndr_currentmonth), { withCallbacks: true });
                        }

                        // si aucune dispo, et que c'est le premier mois chargé alors on passe au mois suivant
                        if(moment().format('M') == month && moment().format('YYYY') == year ){
                            //console.log('next month');
                            $('.clndr-next-button').trigger('click');
                        }
                        $('.clndr-controls').addClass('danger');
                        $('.calendar-error').text('('+data.message+')');
                    }
                    //if (typeof(calendarOldTarget)!='' && updateselecteddays == true) addSelectedDays(calendarOldTarget);
                },
                timeout: function(data, textStatus, errorThrown) {
                    $('.clndr-controls').addClass('danger');
                    $('.calendar-error').text('Timeout');
                },
                error: function(data, textStatus, errorThrown) {
                    //console.log('textStatus: '+textStatus);
                    //console.log('error: '+errorThrown);
                    $('.clndr-controls').addClass('danger');
                    $('.calendar-error').text(textStatus);
                },
                complete: function(data, textStatus) {

                    $('.calendar-loading').hide();
                    $('.clndr-control-button span').show();

                    // reassign previous selection
                    if(clndr_lastdateclicked != null){
                        //console.log('lastdateclicked: '+clndr_lastdateclicked.date.format('YYYY-MM-DD'));
                        selectedBegin = clndr_lastdateclicked.date;
                        selectedEnd = clndr_lastdateclicked.date;

                        if(clndr_lastdateclicked.events.length > 0){
                            discount = clndr_lastdateclicked.events[0].discount;
                            selectedEnd = clndr_lastdateclicked.events[0].date_end;
                            selectedEnd = moment(selectedEnd);
                        }

                        // selection
                        i = 0;
                        var selectedDate = selectedBegin;
                        $('.calendar-day-' + selectedDate.format('YYYY-MM-DD')).addClass('selected active');
                        while(selectedEnd.format('YYYY-MM-DD') != selectedDate.format('YYYY-MM-DD')) {
                            i++;
                            selectedDate = moment(selectedBegin).add(i, 'days');
                            $('.calendar-day-' + selectedDate.format('YYYY-MM-DD')).addClass('selected');

                        }

                    }
                }

            });




        }
        else{
            $('.calendar-loading').hide();
            $('.clndr-control-button span').show();
            //console.log('deja chargé précédemment');

            // reassign previous selection
            if(clndr_lastdateclicked != null){
                //console.log('lastdateclicked: '+clndr_lastdateclicked.date.format('YYYY-MM-DD'));
                selectedBegin = clndr_lastdateclicked.date;
                selectedEnd = clndr_lastdateclicked.date;

                if(clndr_lastdateclicked.events.length > 0){
                    discount = clndr_lastdateclicked.events[0].discount;
                    selectedEnd = clndr_lastdateclicked.events[0].date_end;
                    selectedEnd = moment(selectedEnd);
                }

                // selection
                i = 0;
                var selectedDate = selectedBegin;
                $('.calendar-day-' + selectedDate.format('YYYY-MM-DD')).addClass('selected active');
                while(selectedEnd.format('YYYY-MM-DD') != selectedDate.format('YYYY-MM-DD')) {
                    i++;
                    selectedDate = moment(selectedBegin).add(i, 'days');
                    $('.calendar-day-' + selectedDate.format('YYYY-MM-DD')).addClass('selected');

                }
            }
        }

    }

    var clndr_calendar = $('#calendar').clndr({
        template: $('#calendar-template').html(),
        startWithMonth: clndr_startdate,
        constraints: {
            startDate: clndr_today,
            endDate: clndr_enddate
        },
        adjacentDaysChangeMonth: true,
        forceSixRows: false,
        trackSelectedDate: false,
        /*events: [
            { date: '2016-05-09', title: 'CLNDR GitHub Page Finished', url: 'http://github.com/kylestetz/CLNDR' },
            { date: '2016-05-17', title: 'CLNDR GitHub Page Finished', url: 'http://github.com/kylestetz/CLNDR' },
            { date: '2016-04-12', title: 'CLNDR GitHub Page Finished', url: 'http://github.com/kylestetz/CLNDR' },
            { date: '2016-06-03', title: 'CLNDR GitHub Page Finished', url: 'http://github.com/kylestetz/CLNDR' },
            { date: '2016-06-15', title: 'CLNDR GitHub Page Finished', url: 'http://github.com/kylestetz/CLNDR', price_regular:1543, price_discounted:1543, price_perperson:1543},
        ],*/
        clickEvents: {
            click: function(target) {
                //console.log('click');
                //console.log(target);

                $('.day.mouseover').removeClass('mouseover').removeClass('mouseover-first');
                    clndr_lastdateclicked = target;
                if(target.events.length && !$(target.element).hasClass('inactive') && !$(target.element).hasClass('last-month') && !$(target.element).hasClass('next-month')) {
                    $('.day').removeClass('selected').removeClass('active');
                    $('html, body').animate({ scrollTop: $('#section-summary').offset().top-50}, 600, 'swing');
                    selectedBegin = target.date;
                    $('.day.mouseover').removeClass('mouseover').removeClass('mouseover-first');

                    // price
                    var price_regular = 0;
                    var price_discounted = 0;
                    var discount = 0;
                    var roomorderids = [];
                    var summary = '';
                    var panels = '';
                    $('.calendar-rooms .value').empty();
                    $('.reservation-summary .rooms .value').empty();
                    if(target.events.length > 0){
                        price_regular = target.events[0].price_regular;
                        price_discounted = target.events[0].price_discounted;
                        discount = target.events[0].discount;
                        //_special_promofebruary = (target.events[0]._special_promofebruary == 1 ? 1:0) ;
                        _special_vidangeaquatonic = (target.events[0]._special_vidangeaquatonic == 1 ? 1:0) ;
                        _special_vidangepiscine = (target.events[0]._special_vidangepiscine == 1 ? 1:0) ;
                        _special_fermeturepiscinenmd = (target.events[0]._special_fermeturepiscinenmd == 1 ? 1:0) ;
                        _special_christmas = (target.events[0]._special_christmas == 1 ? 1:0) ;
                        _special_newyearseve = (target.events[0]._special_newyearseve == 1 ? 1:0) ;
                        if(target.events.length > 0){
                            selectedEnd = target.events[0].date_end;
                            selectedEnd = moment(selectedEnd);
                        }
                        selectedEnd = moment(selectedEnd);

                        var newsrc = $('.reservation-summary .price .btn-submit').data('href');
                        //console.log('orig: '+newsrc);
                        newsrc = RemoveParameterFromUrl(newsrc, 'room1_id');
                        newsrc = RemoveParameterFromUrl(newsrc, 'room2_id');
                        newsrc = RemoveParameterFromUrl(newsrc, 'room3_id');
                        var params_room = '';
                        if(clndr_placeisaccommodation){
                            roomorderids = target.events[0].room_order_ids;
                            //console.log('roomorderids:');
                            //console.log(roomorderids);

                            var counter_rooms = 0;

                            $('#roomdescriptions').empty();
                            $('#roomphotos').empty();
                            $('.reservation-summary .rooms .value').empty();

                            var rooms_descriptions = '';
                            var rooms_photos = '';
                            var rooms_summary = '';



                            $.each( roomorderids, function( key, value ) {

                                counter_rooms ++;
                                rooms_descriptions += '<p><span class="roomname"><span class="index">'+ RoomData[value].index_name + ' '+counter_rooms+ RoomData[value].index_separator +' </span>' + RoomData[value].name +' <span class="label label-info label-view label-view-'+RoomData[value].view_id+'">'+RoomData[value].view_name+'</span></span><span class="roomdescription">'+RoomData[value].description+'</span></p>';
                                rooms_photos += '<span class="roomphoto">'+(RoomData[value].photo_url != ''?'<img class="img-responsive" src="'+RoomData[value].photo_url+'" alt="' + RoomData[value].name +'" />': '')+'</span>';

                                rooms_summary += (counter_rooms>1?', ':RoomData[value].index_separator+' ')+RoomData[value].name + (RoomData[value].view_name!='' ? '<span class="label label-info  label-view label-view-'+RoomData[value].view_id+'">'+RoomData[value].view_name+'</span>' :'');

                                params_room += '&room'+counter_rooms+'_id='+value;
                            });


                            /*$.each( roomorderids, function( key, value ) {
                                //console.log( key + ": " + value );
                                //console.log('RoomData['+value+']:');
                                //console.log(RoomData[value].name);
                                counter_rooms++;
                                panels = '<div class="container" data-index="'+counter_rooms+'">' +
                                    '<div class="row">' +
                                    '<div class="col-sm-4">'+(RoomData[value].photo_url != ''?'<img class="img-responsive" src="'+RoomData[value].photo_url+'" alt="' + RoomData[value].name +'" />': '')+'</div>' +
                                    '<div class="col-sm-8"><span class="index">'+ RoomData[value].index_name + ' '+counter_rooms+ RoomData[value].index_separator +' </span>' + RoomData[value].name +' <span class="label label-info label-view label-view-'+RoomData[value].view_id+'">'+RoomData[value].view_name+'</span>' +
                                    RoomData[value].description +
                                    '</div>' +
                                    '</div>'+
                                    '</div>';

                            });*/


                            $('#section-room').attr('data-value', counter_rooms);
                            $('#roomdescriptions').append(rooms_descriptions);
                            $('#roomphotos').append(rooms_photos);
                            $('.reservation-summary .rooms .value').append(rooms_summary);
                            $('.reservation-summary .rooms').attr('data-value', counter_rooms);

                        }


                        affixNavpackage();

                        var params_date = '&date='+selectedBegin.format('YYYY-MM-DD');
                        newsrc = RemoveParameterFromUrl(newsrc, 'date');
                        newsrc += params_room+params_date;
                        console.log('newsrc: '+newsrc);
                        $('.reservation-summary .price .btn-submit').attr('href',newsrc);

                        $('.price-regular .value', clndr_summary).text(price_regular);
                        $('.price-regular', clndr_summary).attr('data-value', (price_discounted == price_regular ? 0: 1));
                        $('.price-discounted .value', clndr_summary).text(price_discounted);
                        $('.discount .value', clndr_summary).text(discount);
                        $('.price', clndr_summary).attr('data-value', price_discounted);
                        $('.discount', clndr_summary).attr('data-value', discount);
                        $('.discount', clndr_summary).attr('data-discount-10', discount > 0);
                        $('.discount', clndr_summary).attr('data-discount-15', discount > 10);
                        $('.discount', clndr_summary).attr('data-discount-20', discount > 15);
                        $('.discount', clndr_summary).attr('data-discount-30', discount > 20);

                        //$('.price .special .promofebruary', clndr_summary).attr('data-value', _special_promofebruary);
                        $('.price .special .vidangeaquatonic', clndr_summary).attr('data-value', _special_vidangeaquatonic);
                        $('.price .special .vidangepiscine', clndr_summary).attr('data-value', _special_vidangepiscine);
                        $('.price .special .fermeturepiscinenmd', clndr_summary).attr('data-value', _special_fermeturepiscinenmd);
                        $('.price .special .christmas', clndr_summary).attr('data-value', _special_christmas);
                        $('.price .special .newyearseve', clndr_summary).attr('data-value', _special_newyearseve);

                    }


                    // selection
                    i = 0;
                    var selectedDate = selectedBegin;
                    $('.calendar-day-' + selectedDate.format('YYYY-MM-DD')).addClass('selected active');
                    while(selectedEnd.format('YYYY-MM-DD') != selectedDate.format('YYYY-MM-DD')) {
                        i++;
                        selectedDate = moment(selectedBegin).add(i, 'days');
                        $('.calendar-day-' + selectedDate.format('YYYY-MM-DD')).addClass('selected');
                    }

                    // date
                    $('.duration .date-from .value', clndr_summary).text(selectedBegin.format("ddd") + ' '+ selectedBegin.format("LL"));
                    $('.duration .date-to .value', clndr_summary).text(selectedEnd.format("ddd") + ' '+ selectedEnd.format("LL"));

                }
            },

            onMonthChange: setEvents,

            onMonthChange2: function(month) {






            },
            today: function(month) {
                //console.log('today ' + month.format('MMMM, YYYY'));
            },


        },
        doneRendering: function() {
            var self = this;
            //console.log('doneRendering');

            //console.log('clndr_currentyear: '+clndr_currentyear);
            //console.log('clndr_currentmonth: '+clndr_currentmonth);

            // Mouseover events
            if($('html').hasClass('no-touchevents')) {
                //console.log('no touch events');
                $(this.element).on('mouseover', '.day.event', function(e) {
                    //console.log('mouseover');
                    var target = self.buildTargetObject(e.currentTarget, true);
                    //console.log(target);
                    selectedBegin = target.date;
                    selectedEnd = target.date;
                    if(target.events.length > 0){
                        selectedEnd = target.events[0].date_end;
                        selectedEnd = moment(selectedEnd);
                    }
                    $('.day.mouseover').removeClass('mouseover').removeClass('mouseover-first');


                    // selection
                    i = 0;
                    var selectedDate = selectedBegin;
                    $('.calendar-day-' + selectedDate.format('YYYY-MM-DD')).addClass('mouseover mouseover-first');
                    while(selectedEnd.format('YYYY-MM-DD') != selectedDate.format('YYYY-MM-DD')) {
                        i++;
                        selectedDate = moment(selectedBegin).add(i, 'days');
                        $('.calendar-day-' + selectedDate.format('YYYY-MM-DD')).addClass('mouseover');

                    }
                });
            }
        }
    });



    //console.log('setMonth: '+parseInt(clndr_currentmonth-1));
    //clndr_calendar.setMonth(parseInt(clndr_currentmonth-1), { withCallbacks: false });
    //clndr_calendar.setYear(parseInt(clndr_currentyear), { withCallbacks: true });

    setEvents(clndr_startdate);

    // Remove mouseover effect
    if($('html').hasClass('no-touchevents')) {
        $('#calendar').on('mouseout', function(){
            $('.day').removeClass('mouseover').removeClass('mouseover-first');
        });
    }

    // Affix
    affixNavpackage();

    $('#navbar-package a').on('click',function(e){
        var offset = 200;
        if($('#summary-affix').hasClass('affix')){
            offset -= 50;
        }
        if($('#navbar-package').hasClass('affix')){
            offset -= 50;
        }

        href = $(this).attr('href');

        $('#navbar-package .navbar-collapse').addClass('collapse').removeClass('in');
        $('html, body').animate({ scrollTop: $(href).offset().top-offset}, 400, 'swing');
    });

    $('.packagelinkdetails').on('click', function(e){
        $('html, body').animate({ scrollTop: $('#section-package').offset().top - 80}, 300, 'swing');
    });

};


pjaxReservation = function (event) {


    //console.log('pjaxReservation');
    //console.log('step:'+step);
    //console.log('oldstep:'+oldstep);
    //console.log('nextstep:'+$('#reservationform-nextstep').val());
    if(step != oldstep){
        $('html, body').animate({ scrollTop: $('#header').offset().top}, 600, 'swing');
    }
    oldstep = step;


    // Affix summary
    $('#summary-affix').off('.affix').affix({
        offset: {
            top:$('#summary-affix').offset().top,
            bottom:0
        }
    });

    // Second email verification
    $("#confirmation_billing_email").on("change", function() {

        if ($("#confirmation_billing_email").val() != $("#reservationform-billing_email").val()) {
            $(".confirmation_billing_email").addClass('has-error');
            const errorText = "<p class=\"help-block help-block-error\"> L'email ne correspond pas !</p>";
            $(".confirmation_billing_email").append(errorText);
            $(".btn-submit"). attr("disabled", true);
        } else {
            if ($(".help-block")) {
                $(".confirmation_billing_email").removeClass('has-error');
                $(".help-block").remove();
                $(".btn-submit"). attr("disabled", false);
            }
            $(".confirmation_billing_email").addClass('has-success');
        }
    })

    // if($("#author_email").val() != $("#author_confirm_email").val())
    // {
    //     alert("emails don't match, sucka");
    // }


    // Smooth link to selection
    $('.reservation-submit .btn-choose').on('click', function(e){
        e.preventDefault();
        $('html, body').animate({ scrollTop: $('#reservation-submit').offset().top -50 }, 300, 'swing');
    });

    // Smooth link to summary
    $('.detailslink a').on('click', function(e){
        e.preventDefault();
        $('html, body').animate({ scrollTop: $('#reservation-summary').offset().top}, 300, 'swing');
    });



    // Nav
    $('.nav-reservation a').on('click', function (e) {
        //console.log('nav-reservation');
        if($(this).attr('href')!='#payment'){
            var step = $(this).data('step');
            $('#reservationform-nextstep').val(step);
            $('.form-reservation').submit();
        }
    });




    // Assign values to input fields
    $('.reservation-box-room1 .btn-choose').on('click', function (e) {
        var id = $(this).data('id');
        $('.field-reservationform-room1_id input[value="' + id + '"]').prop('checked', true);
    });
    $('.reservation-box-room2 .btn-choose').on('click', function (e) {
        var id = $(this).data('id');
        $('.field-reservationform-room2_id input[value="' + id + '"]').prop('checked', true);
    });
    $('.reservation-box-room3 .btn-choose').on('click', function (e) {
        var id = $(this).data('id');
        $('.field-reservationform-room3_id input[value="' + id + '"]').prop('checked', true);
    });
    $('.reservation-box-package1 .btn-choose').on('click', function (e) {
        var id = $(this).data('id');
        $('.field-reservationform-package1_id input[value="' + id + '"]').prop('checked', true);
    });
    $('.reservation-box-package2 .btn-choose').on('click', function (e) {
        var id = $(this).data('id');
        $('.field-reservationform-package2_id input[value="' + id + '"]').prop('checked', true);
    });
    $('.reservation-box-package3 .btn-choose').on('click', function (e) {
        var id = $(this).data('id');
        $('.field-reservationform-package3_id input[value="' + id + '"]').prop('checked', true);
    });
    $('.reservation-box-package4 .btn-choose').on('click', function (e) {
        var id = $(this).data('id');
        $('.field-reservationform-package4_id input[value="' + id + '"]').prop('checked', true);
    });
    $('.reservation-box-package5 .btn-choose').on('click', function (e) {
        var id = $(this).data('id');
        $('.field-reservationform-package5_id input[value="' + id + '"]').prop('checked', true);
    });
    $('.reservation-box-package6 .btn-choose').on('click', function (e) {
        var id = $(this).data('id');
        $('.field-reservationform-package6_id input[value="' + id + '"]').prop('checked', true);
    });
    $('.reservation-box-catering .btn-choose').on('click', function (e) {
        var id = $(this).data('id');
        $('.field-reservationform-catering_id input[value="' + id + '"]').prop('checked', true);
    });
    $('.reservation-box-insurance .btn-choose').on('click', function (e) {
        var id = $(this).data('id');
        $('.field-reservationform-insurance_id input[value="' + id + '"]').prop('checked', true);
    });

    // Choose buttons on panels
    $('.reservation-box .btn-choose').on('click', function (e) {

        //var price =  $(this).data('price');
        //$('.reservation-summary .price .price-discounted .value').text(price);

        var panel_group_clicked = $(this).closest('.panel-group');
        var reservation_panel = $(this).closest('.reservation-box');
        var substep = reservation_panel.closest('.reservation-substep');
        var openable = false;
        var openifactive = false;
        var panel_group_previousactive = reservation_panel.find('.panel-group.active');
        var panel_group_all = reservation_panel.find('.panel-group');

        if (reservation_panel.hasClass('reservation-panel-openable'))
            openable = true;
        if (reservation_panel.hasClass('reservation-panel-openifactive'))
            openifactive = true;

        //console.log('openable: '+openable);
        //console.log('openifactive: '+openifactive);

        //panel_group_all.removeClass('active');
        if (openable && openifactive) {
            //panel_group_all.find('.panel-collapse').collapse('hide').stop().delay(370).queue(function(){
            //panel_group_clicked.find('.panel-collapse').collapse('show');
            //});
        }

        //if(openable && openifactive) panel_group_all.removeClass('panel-group-opened');
        //panel_group_clicked.addClass('active');
        //if(openable && openifactive) panel_group_clicked.addClass('panel-group-opened');


        // only for packages
        if (reservation_panel.hasClass('reservation-panel-closeonchoose')) {
            //panel_group_all.hide();
            //panel_group_clicked.show();
        }

        if (openifactive) {
            //$('html, body').animate({
            //    scrollTop: ($(substep).first().offset().top)
            //},400);
        }
        $('.nav-reservation .active a').trigger('click');
    });

    // Click payment gateway pay button
    $('.form-reservation .btn-pay').on('click', function (e) {
        $('#reservationform-paymentgateway input[value="' + $(this).data('paymentgateway') + '"]').prop('checked', true);
        $('.nav-reservation .active a').trigger('click');
    });

    // Insurance : radio button
    $('.reservation-box-insurance .insurance-option label').on('click', function (e) {
        $('.nav-reservation .active a').trigger('click');
    });

    // Select: change button
    $('.reservation-box select').on('change', function (e) {
        $('.nav-reservation .active a').trigger('click');
    });

    // External Accommodations forms
    $("#reservationform-external_accommodation_id").on("click", function (e) {
        let input = $("#reservationform-external_accommodation_name");
        if ($("input[name='external_accommodation_id']:checked").val() === '7') {
            input.attr('type', 'text');

        } else {
            input.attr('type', 'hidden');
            input.val('');

        }
    })

    // Open panel
    $('.reservation-box .panel-title a').on('click', function (e) {
        var panel_group = $(this).closest('.panel-group');
        var panel_group_collapse = panel_group.find('.panel-collapse');
        if (panel_group_collapse.is(":visible")) {
            panel_group.removeClass('panel-group-opened');
        }
        else {
            panel_group.addClass('panel-group-opened');
        }
    });

    // Btn change
    $('.reservation-box-closeonchoose .btn-change').on('click', function(e){
        //console.log('change');
        $(this).closest('.reservation-box').find('.box').addClass('visible');
        $(this).closest('.box').addClass('showothers');

        pjaxscrollto = $(this).closest('.reservation-substep').offset().top;
    });


    // Packages: hide others
    $('.reservation-box-closeonchoose .btn-hide').on('click', function (e) {
        $(this).closest('.reservation-box').find('.box:not(.showothers)').removeClass('visible');
        $(this).closest('.box').removeClass('showothers').addClass('visible');
    });


    // Owl carousel rooms
    $('.box .owl-carousel').owlCarousel({
        loop:true,
        slideBy: 1,
        items: 1,
        margin:0,
        nav:true,
        dots:true,
        responsiveClass:true,
        navText: [
            '<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span><span class="sr-only">Précédent</span></a>',
            '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span><span class="sr-only">Suivant</span></a>'
        ]
    }).find('.owl-nav').wrap( '<div class="owl-controls-wrapper"></div>' ).wrap( '<div class="owl-controls"></div>' ).find('.owl-next').before('<div class="owl-middle"></div>');



    var newprice = $('#reservationform-depositorfull input:checked').data('price');

    // Deposit/full change
    $('#reservationform-depositorfull input').on('change', function (e) {
        //console.log('#reservationform-depositorfull');
        //console.log($(this).data('price'));
        newprice = $(this).data('price');

        // Change le prix Payline
        var payline_data = {
            payment:{
                amount: newprice * 100, // 25500 = 255€
                currency: payline_currency
            }
        };
        paypalv2Init(newprice);

        $('#PaylineWidget').removeClass('enablebtn');
        Payline.Api.updateWebpaymentData(payline_token, payline_data, function(){
            $('#PaylineWidget').addClass('enablebtn');
        });




    });

    // Accept conditions
    $('#reservationform-acceptconditions').on('change', function (e) {
        if($(this).prop('checked') == true){
            $('#PaylineWidget').addClass('enablebtn');
            paypalv2Init(newprice);
        }
        else{
            $('#PaylineWidget').removeClass('enablebtn');
            $('#paypal-button-container').html('');
        }
    });
    if($('#reservationform-acceptconditions').prop('checked') == true){
        //console.log('accept02');
        $('#PaylineWidget').addClass('enablebtn');

    }

    // Hauteur des box
    matchHeights('.box', '.col-equal', { property: 'min-height' });

    // Payline Payment
    paylineInit();

};

/*if ($('#pjax-reservation').length) {
    console.log('length');
    initReservation();

    initReservationStarted = false;

    if(step == 6){
        console.log('back payment');
        $('a[href="#billing"]').click();
    }

    paylineInit();
}*/

/*
$('#pjax-reservation').on('pjax:complete', function (event, data, status, xhr, options) {
    console.log('pjax:complete');
    initReservation();
    $('html, body').animate({
        scrollTop: ($('#after-nav-reservation').offset().top)
    },400);
    initReservationStarted = true;
    paylineInit();
});

$('#pjax-reservation').on('pjax:end', function (event, data, status, xhr, options) {
    initReservation();
    initReservationStarted = false;
});

$('#pjax-reservation').on('pjax:success', function (event, data, status, xhr, options) {
    console.log('pjax:success');
    paymentRedirect();
});


*/

$('#pjax-reservation').on('pjax:popstate', function (event) {
    //console.log('pjax:popstate');
    if(event.direction == 'back'){
        //console.log('back');
        //console.log($.pjax.state);
        if( $.pjax.state.title == 'Réservation étape #5'){
            //console.log('step5');
            history.back();
        }
    }

});

// **************************************************
// Payment

PaypalMethod = function(){
    //console.log('PaypalMethod');
    return [
        {
            "index": 2, // Index of the payment method in the list
            "paymentMethodId":"paypalmethod", // CSS classes ID (mandatory and unique)
            //"html":'<div id="pl-pmLayout-column-pmForm-paypal-2" class="pl-pmForm" style="display: block;"><div id="pl-pm-custom_5-view" class="pl-paymentMethod-view pl-clear-after"><p id="pl-pm-custom_5-message" class="pl-message"/><div id="pl-pm-custom_5-formContainer" class="pl-form-container pl-hide-labels"><div id="pl-pm-custom_5-accountContainer" class="pl-form-group pl-has-feedback pl-accountContainer pl-clear-after"><p id="pl-pm-custom_5-account_message" class="pl-message"/><div class="pl-input-group-container"><div class="pl-input-group pl-input-container"><input id="pl-pm-custom_5-account" class="pl-input" placeholder="Numéro de carte"><span id="pl-pm-custom_5-feedbackAccount" class="pl-glyphicon pl-input-feedback" aria-hidden="true"/></div><span class="pl-form-control-addon pl-icon-credit-card"/></div>        </div><button id="pl-pm-custom_5-payBtn" class="pl-pay-btn" type="button" onclick="customPayment()">Payer</button></div></div></div>' // HTML code of your payment method (mandatory)
            "html":'test' // HTML code of your payment method (mandatory)
        }
    ];
};

paypalv2Init = function(amount, reference, returnurl){

    if($('#reservationform-acceptconditions').prop( 'checked' ) === false)
        return;

    console.log('paypalv2Init with amount ' + amount);
    reference = paypal_reference;
    returnurl = paypal_returnurl;
    $("#paypal-button-container").html('');

    var paypalButtonsComponent = paypal.Buttons({
        fundingSource: paypal.FUNDING.PAYPAL,
        // optional styling for buttons
        // https://developer.paypal.com/docs/checkout/standard/customize/buttons-style-guide/
        style: {
            color: "white",
            shape: "pill",
            layout: "vertical",
            label: "pay",
            tagline: false,
        },

        // set up the transaction
        createOrder: (data, actions) => {
            $('#paypal-button-errors').html('');
            // pass in any options from the v2 orders create call:
            // https://developer.paypal.com/api/orders/v2/#orders-create-request-body
            const createOrderPayload = {
                processing_instruction: 'ORDER_COMPLETE_ON_PAYMENT_APPROVAL',
                purchase_units: [
                    {
                        reference_id: reference,
                        amount: {
                            value: amount
                        }
                    }
                ]
            };

            return actions.order.create(createOrderPayload);
        },

        // finalize the transaction
        onApprove: (data, actions) => {
            const captureOrderHandler = (details) => {
                const payerName = details.payer.name.given_name;
                console.log('Transaction completed');
                console.log('Capture result', details, JSON.stringify(details, null, 2));

                console.log('transaction id: ' + details.id);
                console.log('status: ' + details.status);
                console.log('payer_id: ' + details.payer.payer_id);

                window.location = returnurl + '?paymentId=' + details.id;
            };

            return actions.order.capture().then(captureOrderHandler);
        },

        // handle unrecoverable errors
        onError: (err) => {
            $('#paypal-button-errors').html(JSON.stringify(err, null, 2));
            console.error('An error prevented the buyer from checking out with PayPal');
            console.log('Error', err, JSON.stringify(err, null, 2));
        }
    });

    paypalButtonsComponent
        .render("#paypal-button-container")
        .catch((err) => {
            console.error('PayPal Buttons failed to render');
        });
}


paylineInit = function () {
    //console.log('paylineInit');



    if (typeof payline_token === 'undefined' || payline_token === null) {
        //console.log('FILE payline_token: undefined');
    }
    else{
        //console.log('FILE payline_token:' + payline_token);
        //console.log(Payline);
        //console.log(Payline.API);
        //var paylinewidget = $('<div id="PaylineWidget" data-template="column" data-auto-init="false"></div>').appendTo('#PaylineWidgetWrapper');
        Payline.Api.init(payline_token);
        window.scrollTo(500, 0);

        /*
        console.log('#reservationform-acceptconditions: '+ $('#reservationform-acceptconditions').prop( 'checked' ));
        if($('#reservationform-acceptconditions').prop( 'checked' ) == true){
            console.log('accept03');
            $('#PaylineWidget').addClass('enablebtn');
        }
        else{
            $('#PaylineWidget').removeClass('enablebtn');
        }

        $( "body" ).delegate( ".pl-pay-btn", "click", function( e ) {
            console.log('#reservationform-acceptconditions: '+ $('#reservationform-acceptconditions').prop( 'checked' ));
            if($('#reservationform-acceptconditions').prop( 'checked' ) == false){
                e.preventDefault();
                var $form = $('#ReservationForm');
                $form.yiiActiveForm('validate');
                $form.submit();
                return false;
            }
            else{
            }
        } );*/

    }



};

paymentRedirect = function () {
    //console.log('paymentRedirect');

    if (typeof payment_redirecturl === 'undefined' || payment_redirecturl === null) {
        //console.log('FILE payment_redirecturl: undefined');
    }
    else{
        //console.log('FILE payment_redirecturl:' + payment_redirecturl);
        window.location = payment_redirecturl;
    }
};

// **************************************************
// Open tab from link
openTabFromLink = function() {
    var hash = document.location.hash;
    if (hash) {
        $('.nav-tabs a[href=\\'+hash+']').tab('show');
    }

    // Change hash for page-reload
    $('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
        window.location.hash = e.target.hash;
    });
}
$(document).ready(function($){
    openTabFromLink();
});

// **************************************************
// Room Gallery

openRoomGallery = function() {
    var previousRoomSelected = $('#calendarsearch-room_id_searched').attr('data-previousvalue');

    $('#roomgallery-modal').remove();

    var roomgallery_content = [];

    counter_rooms = 0;
    if(RoomData){
        $.each( RoomData, function( key, value ) {
            if(RoomData[key].status == 1) {
                counter_rooms ++;
                photos_content = '';
                if(RoomData[key].photos.length > 1){
                    photos_content += '<div class="owl-carousel owl-carousel-room">';
                }
                $.each( RoomData[key].photos, function( photo_key, photo_value ) {
                    photos_content += '<div class="item">';
                    photos_content += '<img src="'+photo_value+'" alt="'+RoomData[key].name+'" class="img-responsive"/>';
                    photos_content += '</div>';
                });
                if(RoomData[key].photos.length > 1){
                    photos_content += '</div>';
                }

                if(counter_rooms)
                    roomgallery_content[RoomData[key].displayorder] = '' +
                        '<div class="col-sm-6 col-lg-4 col-room">' +
                        '<div class="room selectable '+(previousRoomSelected == RoomData[key].room_id ? 'active':'')+'" data-roomid="'+RoomData[key].room_id+'" data-status="'+RoomData[key].status+'">' +
                        '<div class="roomphoto">' +
                        photos_content +
                        '</div>'+
                        '<div class="roommeta">' +
                        '<p class="h4 roomname">' +
                        //RoomData[key].index_name +
                        ' '+ RoomData[key].name +
                        ' <span class="label label-info label-view label-view-'+RoomData[key].view_id+'">'+RoomData[key].view_name+'</span>' +
                        '</p>'+
                        '<p class="roomdescription">' +
                        RoomData[key].description +
                        '</p>'+
                        '<a href="#" class="btn btn-primary btn-chooseroom" data-roomid="'+RoomData[key].room_id+'">' +
                        (previousRoomSelected == RoomData[key].room_id ? RoomGalleryText.roomactive:RoomGalleryText.book) +
                        '</a>' +
                        '</div>'+
                        '</div>'+
                        '</div>';
            }


        });
    }




    /*var roomgallery_close = $('<a href="#" class="btn btn-inverse"></a>').text(RoomGalleryText.close).on('click',function(e){
     $('#roomgallery').remove();
     $('html').removeClass('noscroll');
     });*/
    var roomgallery_close = '<button class="btn btn-primary btn-closeroomgallery">'+RoomGalleryText.close+' <span class="glyphicon glyphicon-remove"></span></button>';

    var roomgallery = '';
    var roomgallery = $( '' +
        '<div id="roomgallery-modal">' +
        '<div class="roomgallery">' +


        '<div class="container">' +
        '<p class="lead intro">'+RoomGalleryText.intro+roomgallery_close +'</p>' +
        '<div class="row">' +
        roomgallery_content.join('') +
        '</div>'+
        roomgallery_close+
        '</div>'+
        '</div>'+
        '</div>'
    ).appendTo('#CalendarSearch');


    //$('#CalendarSearch').append(roomgallery);
    setTimeout(function(){
        if(formopened == false){
            $('html').addClass('noscroll');
        }
        roomgallery.addClass('open');
    }, 200);

    $('#roomgallery-modal .room.selectable .btn').on('click', function(e){
        $('#calendarsearch-room_id_searched').val($(this).data('roomid')).selectpicker('refresh').attr('data-previousvalue', $(this).data('roomid'));
        $('#calendarsearch-room_id_searched').closest('.form-group').removeClass('has-error');
        $('#roomgallery-modal').remove();
        if(formopened == false){
            $('html').removeClass('noscroll');
        }
        $('html, body').animate({ scrollTop: $('#section-calendar').offset().top}, 400, 'swing');
        $('#CalendarSearch').submit();
    });

    $('#roomgallery-modal .btn-closeroomgallery').on('click', function(e){
        $('#calendarsearch-room_id_searched').val(previousRoomSelected).selectpicker('refresh').attr('data-previousvalue', previousRoomSelected);
        $('#calendarsearch-room_id_searched').closest('.form-group').removeClass('has-error');
        $('#roomgallery-modal').remove();
        $('html').removeClass('noscroll');
    });

    // owl carousel des chambres
    $('#roomgallery-modal .owl-carousel-room').owlCarousel({
        loop:true,
        slideBy: 1,
        items: 1,
        margin:0,
        nav:true,
        dots:true,
        responsiveClass:true,
        navText: [
            '<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span><span class="sr-only">Précédent</span></a>',
            '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span><span class="sr-only">Suivant</span></a>'
        ],
    }).find('.owl-nav').wrap( '<div class="owl-controls-wrapper"></div>' ).wrap( '<div class="owl-controls"></div>' ).find('.owl-next').before('<div class="owl-middle"></div>');

    // Hauteur des chambres
    matchHeights('.roomgallery', '.room');

};

// **************************************************
// Select
$('.bootstrapselect').selectpicker({mobile:is_mobile});

$('select').each(function(){
    if($(this).val() == '') $(this).addClass('empty');
    else $(this).removeClass('empty')}).on('change', function(){
    if($(this).val() == '')
        $(this).addClass('empty');
    else
        $(this).removeClass('empty')
});

// **************************************************
// Tabs
$('#tab-access').on('shown.bs.tab', function (e) {
    googlemaps_resize();
});
$('#tab-room').on('shown.bs.tab', function (e) {
    //console.log('matchHeights');
    matchHeights('.roomgallery', '.room');

});


$('.nav-place').on('shown.bs.tab', function (e) {
    // relancer l'affix si on change d'onglet
    affixPriceSearch();
});

// **************************************************
// Owl Carousel

$('.owl-carousel-default').owlCarousel({
    loop:true,
    slideBy: 1,
    margin:0,
    nav:true,
    dots:true,
    responsiveClass:true,
    navText: [
        '<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span><span class="sr-only">Précédent</span></a>',
        '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span><span class="sr-only">Suivant</span></a>'
    ],
    responsive:{
        0:{
            items:1,
            slideBy: 1
        },
        768:{
            items:2,
            slideBy: 2
        },
        992:{
            items:3,
            slideBy: 3
        },
        1200:{
            items:4,
            slideBy: 4
        }
    }
}).find('.owl-nav').wrap( '<div class="owl-controls-wrapper"></div>' ).wrap( '<div class="owl-controls"></div>' ).find('.owl-next').before('<div class="owl-middle"></div>');

$('.owl-carousel-package').owlCarousel({
    loop:true,
    slideBy: 1,
    items: 1,
    margin:10,
    nav:true,
    dots:true,
    responsiveClass:true,
    navText: [
        '<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span><span class="sr-only">Précédent</span></a>',
        '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span><span class="sr-only">Suivant</span></a>'
    ],
    responsive:{
        0:{
            items:1,
            slideBy: 1
        },
        768:{
            items:2,
            slideBy: 1
        },
        992:{
            items:3,
            slideBy: 1
        },
        1200:{
            items:4,
            slideBy: 2
        }
    }
}).find('.owl-nav').wrap( '<div class="owl-controls-wrapper"></div>' ).wrap( '<div class="owl-controls"></div>' ).find('.owl-next').before('<div class="owl-middle"></div>');



$('.owl-carousel-activities .item').each(function(){

    // Only for Nouveau Monde
    if( ! $('body').hasClass('placeslug-hotel-le-nouveau-monde')) {
        if($(this).hasClass('only-nouveau-monde')){
            $(this).remove();
        }
    }

    // Exclude for Escapade Spa
    if($('body').hasClass('packageslug-escapade-spa') || $('body').hasClass('packageslug-escapade-spa-nouveau-monde')) {

        if( $('body').hasClass('placeslug-hotel-le-nouveau-monde')) {
            if($(this).hasClass('escapade-spa-not-nouveau-monde')){
                $(this).remove();
            }
        }
    }

    // Escapade Aquatonic
    if($('body').hasClass('packageslug-escapade-aquatonic')){

        // Supprimer La piscinedétente sur l'escapade Aquatonic pour autres etablissements que GHT
        if($(this).hasClass('only-grandhotel')){
            if($('body').hasClass('placeslug-le-grand-hotel-des-thermes')){

            }
            else{
                $(this).remove();
            }
        }

        // Supprimer Eveil Musculaire et Brouillard Marin sur Escapade Aquatonic
        if($(this).hasClass('not-escapadeaquatonic')){
            $(this).remove();
        }
    }
});

$('.owl-carousel-activities').owlCarousel({
    loop:true,
    slideBy: 1,
    items: 1,
    margin:20,
    nav:true,
    dots:true,
    responsiveClass:true,
    navText: [
        '<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span><span class="sr-only">Précédent</span></a>',
        '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span><span class="sr-only">Suivant</span></a>'
    ],
    responsive:{
        0:{
            items:1,
            slideBy: 1
        },
        768:{
            items:2,
            slideBy: 1
        },
        992:{
            items:3,
            slideBy: 1
        },
        1200:{
            items:4,
            slideBy: 2
        }
    }
}).find('.owl-nav').wrap( '<div class="owl-controls-wrapper"></div>' ).wrap( '<div class="owl-controls"></div>' ).find('.owl-next').before('<div class="owl-middle"></div>');

$('.owl-carousel-place').owlCarousel({
    loop:true,
    slideBy: 1,
    items: 1,
    margin:0,
    nav:true,
    dots:true,
    responsiveClass:true,
    navText: [
        '<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span><span class="sr-only">Précédent</span></a>',
        '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span><span class="sr-only">Suivant</span></a>'
    ]
}).find('.owl-nav').wrap( '<div class="owl-controls-wrapper"></div>' ).wrap( '<div class="owl-controls"></div>' ).find('.owl-next').before('<div class="owl-middle"></div>');

$('.owl-carousel-packagedetails').owlCarousel({
    loop:true,
    slideBy: 1,
    items: 1,
    margin:0,
    nav:true,
    dots:true,
    responsiveClass:true,
    navText: [
        '<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span><span class="sr-only">Précédent</span></a>',
        '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span><span class="sr-only">Suivant</span></a>'
    ]
}).find('.owl-nav').wrap( '<div class="owl-controls-wrapper"></div>' ).wrap( '<div class="owl-controls"></div>' ).find('.owl-next').before('<div class="owl-middle"></div>');

$('.owl-carousel-placedetails').owlCarousel({
    loop:true,
    slideBy: 1,
    items: 1,
    margin:0,
    nav:true,
    dots:true,
    responsiveClass:true,
    navText: [
        '<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span><span class="sr-only">Précédent</span></a>',
        '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span><span class="sr-only">Suivant</span></a>'
    ]
}).find('.owl-nav').wrap( '<div class="owl-controls-wrapper"></div>' ).wrap( '<div class="owl-controls"></div>' ).find('.owl-next').before('<div class="owl-middle"></div>');

$('.owl-carousel-restaurant').owlCarousel({
    loop:true,
    slideBy: 1,
    items: 1,
    margin:0,
    nav:true,
    dots:true,
    responsiveClass:true,
    navText: [
        '<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span><span class="sr-only">Précédent</span></a>',
        '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span><span class="sr-only">Suivant</span></a>'
    ]
}).find('.owl-nav').wrap( '<div class="owl-controls-wrapper"></div>' ).wrap( '<div class="owl-controls"></div>' ).find('.owl-next').before('<div class="owl-middle"></div>');

$('.owl-carousel-room').owlCarousel({
    loop:true,
    slideBy: 1,
    items: 1,
    margin:0,
    nav:true,
    dots:true,
    responsiveClass:true,
    navText: [
        '<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span><span class="sr-only">Précédent</span></a>',
        '<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span><span class="sr-only">Suivant</span></a>'
    ]
}).find('.owl-nav').wrap( '<div class="owl-controls-wrapper"></div>' ).wrap( '<div class="owl-controls"></div>' ).find('.owl-next').before('<div class="owl-middle"></div>');

// **************************************************
// Helpers

// Supprimer des paramètres de l'url
RemoveParameterFromUrl = function (url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts= url.split('?');
    if (urlparts.length>=2) {

        var prefix= encodeURIComponent(parameter)+'=';
        var pars= urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (var i= pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        url= urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
        return url;
    } else {
        return url;
    }
};


// Egaler la hauteur des blocs
matchHeights = function(main_element, sub_items, options){
    setTimeout(() => {
        $(main_element).each(function() {
            $(this).find(sub_items).matchHeight(options);
        });
    }, 500)
};

// **************************************************
// Check Coupon

CheckCoupon = function() {


    var coupon_check_url = app_config.HOMEURL + '/' + app_config.LANGUAGE+ '/reservation/check-coupon';

    $('#coupon_code').on('keypress', function(e) {
        if(e.which === 13 ){
            e.preventDefault();
            $('#btn-apply-coupon').trigger('click');
        }
    });

    $('#btn-apply-coupon').on('click', function(e){
        e.preventDefault();

        $('#coupon-result').html('').hide();
        var csrfToken = $('meta[name="csrf-token"]').attr("content");
        var coupon_code_tocheck = $('#coupon_code').val();
        var booking_token = $('#reservationform-booking_token').val();

        $.ajax({
            url: coupon_check_url,
            method: 'post',
            cache: false,
            data: {
                '_csrf' : csrfToken,
                'CouponForm[code]': coupon_code_tocheck,
                'CouponForm[booking_token]': booking_token,
            },
            success: function(data, textStatus) {

                if(data && data.coupon_form && data.coupon_form.error){
                    $('#coupon-result').html(data.coupon_form.error).show();
                }
                else{
                    $('#reservationform-nextstep').val(5);
                    $('#reservationform-nextstep').attr('data-previous', 4);
                    $('#reservation-submit .btn-submit').trigger('click');
                }

            },
            timeout: function(data, textStatus, errorThrown) {

            },
            error: function(data, textStatus, errorThrown) {
                console.log('textStatus: '+textStatus);
                console.log('error: '+errorThrown);

            },

        });
    });




}


// For iAdvize
var iadvizeDevice = 'desktop';
var regex = new RegExp("(android|iphone|ipad|blackberry|symbian|symbianos|" +
    "symbos|netfront|model-orange|javaplatform|iemobile|windows phone|samsung|htc|" +
    "opera mobile|opera mobi|opera mini|presto|huawei|blazer|bolt|doris|fennec|" +
    "gobrowser|iris|maemo browser|mib|cldc|minimo|semc-browser|skyfire|teashark|" +
    "teleca|uzard|uzardweb|meego|nokia|bb10|playbook)","gi");
if (navigator.userAgent.match(regex)){
    if (((screen.width >= 480) && (screen.height >= 800)) || ((screen.width >= 800) &&
        (screen.height >= 480)) || navigator.userAgent.match(/ipad/gi)){
        iadvizeDevice = 'tablet';
    } else {
        iadvizeDevice = 'mobile';
    }
} else {
    iadvizeDevice = 'desktop';
}
dataLayer.push({'IadvizeDevice': iadvizeDevice});

var page_type = 'page';
var CriteoPageType = 'ListingPage';

if($('body').hasClass('controller-search')){
    page_type = 'recherche';
    CriteoPageType = 'ListingPage';
}
if($('body').hasClass('controller-place')){
    page_type = 'hebergement';
    CriteoPageType = 'ListingPage';
}
if($('body').hasClass('controller-package')){
    page_type = 'produit';
    CriteoPageType = 'ProductPage';
}
if($('body').hasClass('controller-reservation')){
    page_type = 'reservation';
    CriteoPageType = 'BasketPage';
}
if($('body').hasClass('action-confirmation-payline') || $('body').hasClass('action-confirmation-paypal') ){
    page_type = 'paiement';
    CriteoPageType = 'TransactionPage';
    if(CriteoPushTransaction == false){
        CriteoPageType = '';
    }
}
var tripid = $('body').data('tripid');

CriteoProductIDList = CriteoProductIDList.slice(0, 3);
dataLayer.push({'PageType': page_type});
dataLayer.push({'IadvizePageType': page_type});
dataLayer.push({'SquaddataPageType': page_type});

if ( ( parseInt(tripid) === 1 || tripid === '' ) && CriteoPageType != '' ) {
    dataLayer.push({'CriteoPageType': CriteoPageType});
}


dataLayer.push({'CustomerType': ''});
dataLayer.push({'CriteoProductIDList': CriteoProductIDList});
dataLayer.push({'CriteoBasketProducts': CriteoBasketProducts});
dataLayer.push({'CriteoTransProducts': CriteoTransProducts});
