var singleMap;

function googlemaps_init() {
    if (typeof googlemaps_single_data !== 'undefined') {
        googlemaps_display(googlemaps_single_data.lat, googlemaps_single_data.lng, googlemaps_single_data.zoom);
    }
}

function googlemaps_display(lat, lng, zoom) {
    if (typeof zoom === 'undefined') {
        zoom = 13;
    }

    var singleLatLng = new google.maps.LatLng(lat, lng);

    var mapOptions = {
        scrollwheel: false,
        zoom: zoom,
        center: singleLatLng,
        //styles: [{"featureType":"all","stylers":[{"saturation":0},{"hue":"#e7ecf0"}]},{"featureType":"road","stylers":[{"saturation":-70}]},{"featureType":"transit","stylers":[{"visibility":"off"}]},{"featureType":"poi","stylers":[{"visibility":"on"}]},{"featureType":"water","stylers":[{"visibility":"simplified"},{"saturation":-60}]}]
    }
    singleMap = new google.maps.Map(document.getElementById('googlemaps-single'), mapOptions);

    var iconMarker = new google.maps.MarkerImage("/img/mapmarker.png", null, null, null, new google.maps.Size(40, 40));
    var singleMarker = new google.maps.Marker({
        position: singleLatLng,
        map: singleMap,
        icon: iconMarker
    });

    // Centre Thalasso
    var singleLatLng = new google.maps.LatLng('48.658315', '-1.998981');
    var iconMarker = new google.maps.MarkerImage("/img/mapmarker-highlight.png", null, null, null, new google.maps.Size(40, 40));
    var singleMarker = new google.maps.Marker({
        position: singleLatLng,
        map: singleMap,
        icon: iconMarker,
        zIndex: -20
    });

    //document.getElementById('googlemaps-single').className ='loaded';

}


function googlemaps_resize() {
    if(singleMap){
        var center = singleMap.getCenter();
        google.maps.event.trigger(singleMap, "resize");
        singleMap.setCenter(center);
    }

}

